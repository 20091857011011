<template>
  <v-app>
    <v-snackbar :timeout="3000" v-model="alert.show" :color="alert.color" top>
      {{ alert.message }}
      <v-icon small dark @click="alert.show = false">close</v-icon>
    </v-snackbar>
    <v-overlay v-if="loading == true" z-index="9">
      <LoadingPage LoadingMessage="Please wait a minute ..."></LoadingPage>
    </v-overlay>
    <dashboard-core-app-bar />
    <dashboard-core-drawer />
    <dashboard-core-view />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "DashboardIndex",
  components: {
    LoadingPage: () => import("@/components/base/LoadingPage"),
    DashboardCoreAppBar: () => import("@/components/layout/AppBar"),
    DashboardCoreDrawer: () => import("@/components/layout/Drawer"),
    DashboardCoreView: () => import("@/components/layout/View")
  },
  computed: {
    ...mapState({
      alert: state => state.global.alert,
      loading: state => state.global.loading
    })
  }
};
</script>
